import React from "react";
import { InputText } from 'primereact/inputtext';

const SimpleFilter = (props) => {
  return (
    <InputText
      value={props.value || ""}
      onInput={(e) => props.filterCallback(e.target.value)}
    />
  );
};

export default SimpleFilter;
